import React from 'react';
import { Row, Col } from 'antd';
import Container from './Container';
import Culture1 from '../images/culture-1.jpg';
import Culture2 from '../images/culture-2.jpg';
import Culture3 from '../images/culture-3.jpg';
import Culture4 from '../images/culture-4.jpg';

const CULTURE_DATA = [
  {
    id: 'culture-1',
    img: Culture1,
    alt: '路径，由我们规划',
  },
  {
    id: 'culture-2',
    img: Culture2,
    alt: '体验，由我们创造',
  },
  {
    id: 'culture-3',
    img: Culture3,
    alt: '同伴，由我们赋能',
  },
  {
    id: 'culture-4',
    img: Culture4,
    alt: '未来，由我们打造',
  },
];

function CorporateCulture() {
  return (
    <div id="corporate-culture" className="corporate-culture">
      <Container>
        <Row gutter={{ lg: 28, md: 15, sm: 30, xs: 30 }}>
          {CULTURE_DATA.map((item) => (
            <Col lg={6} md={6} sm={6} xs={12} key={item.id}>
              <img width="100%" src={item.img} alt={item.alt} key={item.id} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default CorporateCulture;
