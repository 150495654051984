import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Container from './Container';

const OurItem = ({ preTitle, title, contentList = [] }) => {
  return (
    <div className="our-item">
      <h4>{preTitle}</h4>
      <h3>{title}</h3>
      {contentList.map((item) => (
        <p key={item.id}>{item.content}</p>
      ))}
    </div>
  );
};

OurItem.propTypes = {
  preTitle: PropTypes.string,
  title: PropTypes.string,
  contentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.string,
    })
  ),
};

function CompanyProfile() {
  return (
    <div id="company-profile" className="company-profile">
      <Container>
        <div className="basic-info">
          <Row>
            <Col md={10} sm={24} xs={24} className="left">
              <h4>上海欣兆阳信息科技有限公司</h4>
              <p>
                Convertlab成立于2015年，是中国强大的一体化营销云企业，并于2020年完成由腾讯产业生态投资和光速中国领投的超一亿元B轮融资。
              </p>
            </Col>
            <Col span={14} className="right">
              <div className="company-image" />
            </Col>
          </Row>
        </div>
        <div className="our-info">
          <Row gutter={30}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <OurItem
                preTitle="我们相信什么"
                title="WHAT DO WE BELIEVE"
                contentList={[
                  {
                    id: '1-1',
                    content:
                      '我们相信，在数字化的今天，智能化的前夜，所有的企业都需要拥抱以云计算、大数据、人工智能为代表的数字技术，迎接下一波属于产业的黄金时代。更早获得并融合这些能力，也是中国接下来5年引领全球经济的重要支撑点。',
                  },
                  {
                    id: '1-2',
                    content:
                      '同时我们也相信，“优化、预测、决策”这些和数智化相关的能力，在接下来的5年里，具备大规模落地企业的可行性，甚至应用到企业之外的诸多领域。',
                  },
                  {
                    id: '1-3',
                    content:
                      '我们选择从营销端切入这些领域，过程中探索属于数字化时代的，有别于传统企业应用的全新之路。',
                  },
                ]}
              />
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <OurItem
                preTitle="我们在做什么"
                title="What We Do"
                contentList={[
                  {
                    id: '2-1',
                    content:
                      '我们为企业营销提供数字化、自动化、智能化基础设施以及相关的专业服务，帮助企业高效运营海量用户，打造品牌特有的个性化用户体验，同时快速收获业务增长。目前，Convertlab已为超过300家知名企业提供企业级服务并获得最佳实践，并在零售、快消、耐消、金融、企业服务等行业内形成大量客户成功案例。',
                  },
                ]}
              />
              <OurItem
                preTitle="我们的目标是什么"
                title="Where We’re Going"
                contentList={[
                  {
                    id: '2-1',
                    content:
                      '深度洞察客户，帮助企业用最有效的方式获得客户并传递价值，提升客户体验，在此基础之上，探索数智化在各个领域内的有意义且有挑战的应用。',
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default CompanyProfile;
