import React from 'react';
import PropTypes from 'prop-types';
import Container from './Container';

function AboutUsBanner({ title, description, imgName }) {
  return (
    <section id="about-us-banner" className={`about-us-banner ${imgName}`}>
      <div className="mask">
        <Container>
          <h2>{title}</h2>
          <div className="banner-divider" />
          <p>{description}</p>
        </Container>
      </div>
    </section>
  );
}

AboutUsBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imgName: PropTypes.string,
};

export default AboutUsBanner;
