// import React, { useState } from 'react';
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Container from './Container';
import FollowUs from '../images/qrcode/official_contactus.jpg';
import { loadQrcodeQw } from '../utils/common';

const POSITION_LIST = [
  {
    title: '上海总部',
    id: 'shanghai',
    detail: '上海市黄浦区云南南路118号凯腾大厦',
  },
  {
    title: '北京',
    id: 'beijing',
    detail: '北京市朝阳区望京街10号望京SOHO T2-A座-2202',
  },
  {
    title: '深圳',
    id: 'shenzhen',
    detail: '深圳市福田区中康路卓越城一期1号楼1201B',
  },
  {
    title: '西安',
    id: 'xian',
    detail: '西安市高新区锦业一路10号中投国际B座17层',
  },
  {
    title: '成都（远程交付中心RDC）',
    id: 'chengdu',
    detail: '四川省成都市高新区天府大道北段869号数字经济大厦12楼',
  },
];

function Contacts() {
  // const [currentPosition, setCurrentPosition] = useState('shanghai');

  // const PositionItem = ({ id, title }) => {
  //   return (
  //     <button
  //       className={`position-item ${id} ${currentPosition === id ? 'active' : ''}`}
  //       onMouseEnter={() => setCurrentPosition(id)}
  //       type="button"
  //     >
  //       {/* <span className="content">{title}</span> */}
  //       <span className="pointer" />
  //     </button>
  //   );
  // };
  //
  // PositionItem.propTypes = {
  //   id: PropTypes.string,
  //   title: PropTypes.string,
  // };

  // const activePosition = POSITION_LIST.find((item) => item.id === currentPosition);

  useEffect(() => {
    loadQrcodeQw('onlineConsulting2');
  }, []);
  return (
    <section id="contacts" className="contacts">
      <a href="#contacts" className="anchor" />
      <h3>联系我们</h3>
      <Container>
        {/*<Row className="position-map-wrapper">*/}
        {/*  <Col lg={12} md={24}>*/}
        {/*    <div className="map-wrapper">*/}
        {/*      <div className="position-list">*/}
        {/*        {POSITION_LIST.map((item) => (*/}
        {/*          <PositionItem key={item.id} title={item.title} id={item.id} />*/}
        {/*        ))}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*  <Col lg={12} md={24} className="detail-wrapper">*/}
        {/*    <div className="position-detail">*/}
        {/*      <p className="title">{activePosition.title}</p>*/}
        {/*      <p className="label">地址：</p>*/}
        {/*      <p className="detail">{activePosition.detail}</p>*/}
        {/*    </div>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <div className="position-list-mobile">
          {POSITION_LIST.map((positionItem) => (
            <div key={positionItem.id} className="position-row">
              <h2>{positionItem.title}</h2>
              <p>{positionItem.detail}</p>
            </div>
          ))}
        </div>
        <Row className="contact-footer">
          <Col lg={8} md={8} sm={24} xs={24}>
            <div className="footer-icon-item">
              <span className="iconfont blue font-60 iconphone-circle" />
              <div className="item-wrapper">
                <p>咨询电话：</p>
                <p>
                  <a href="tel://4008509918">400-850-9918</a>
                </p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <div className="footer-icon-item">
              <span className="iconfont blue font-60 iconmail-circle" />
              <div className="item-wrapper">
                <p>邮箱：</p>
                <p>
                  <a href="mailto:info@convertlab.com">info@convertlab.com</a>
                </p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <div className="qr-code-wrapper">
              <div className="qrCode-item">
                <div id="_cl_qrcode_contactus">
                  <img src={FollowUs} className="qrCode" alt="QR Code" />
                </div>
                <div className="title">关注我们</div>
              </div>
              <div className="qrCode-item">
                <div
                  className="qrCode"
                  alt="QR Code"
                  id="_cl_wework_qrcode_online_consulting_contactus"
                >
                  <img
                    src="https://wework.qpic.cn/wwpic/168992_lwhraIS7RKyPRwy_1698634963/0"
                    className="qrCode"
                    alt="QR Code"
                  />
                </div>
                <div className="title">在线咨询</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Contacts;
