import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import TitledVerticalBoxes from './TitledVerticalBoxes';

const RadiusIconBox = ({ title, description, id }) => {
  return (
    <Col lg={6} md={6} sm={12} xs={12} className="same">
      <div className={`vertical-box ${id}`}>
        {title && <span className="title">{title}</span>}
        <div className="divider" />
        <span className="description">{description}</span>
      </div>
    </Col>
  );
};

RadiusIconBox.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

const VALUE_DATA = [
  {
    id: 'value-1',
    title: '客户导向',
    description: '对客户充满热情，在承诺、结果方面打造个人品牌',
  },
  {
    id: 'value-2',
    title: '专业',
    description: '保持学习心态，工作中坚持自我提高和相互分享',
  },
  {
    id: 'value-3',
    title: '创新',
    description: '对新的技术和市场充满自信并全力挑战不断创新',
  },
  {
    id: 'value-4',
    title: '团队协作',
    description: '与同事、合作伙伴真诚相处鼎力合作',
  },
];

function CoreValues() {
  return (
    <div id="core-values" className="core-values">
      <TitledVerticalBoxes title="我们的核心价值观" radius>
        {VALUE_DATA.map((value) => (
          <RadiusIconBox key={value.id} {...value} />
        ))}
      </TitledVerticalBoxes>
    </div>
  );
}

export default CoreValues;
