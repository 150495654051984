import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Fade from 'react-reveal/Fade';
import Container from './Container';

const HISTORY_LIST = ['2021', '2020', '2019', '2018', '2017', '2016', '2015'];

const HISTORY_MAP = {
  2021: [
    { month: '5月', content: '国内唯一入选The Forrester Wave™：亚太地区跨渠道营销活动管理（CCCM）厂商' },
    { month: '6月', content: 'Demo Day：Convertlab 全景全链路营销云产品发布' },
    { month: '7月', content: 'Forrester&Convertlab 联合发布中国MA报告' },
    { month: '10月', content: '在上海举办第四届营销技术峰会MTSC「云领时代 智绘未来」' },
    { month: '12月', content: 'CPM同意和偏好管理产品“特别”发布会' },
  ],
  2020: [{ month: '10月', content: '完成由腾讯产业生态投资和光速中国领投的超一亿元B轮融资' }],
  2019: [
    { month: '3月', content: 'AMA项目启动' },
    { month: '5月', content: '与浦发集团內私募基金管理人浦信资本签订投资意向书' },
    { month: '9月', content: '发布以平台化、智能化、生态化为主要增强的DM Hub3.0产品' },
    { month: '10月', content: '在上海举办第三届中国MarTech（MTCC2019）峰会' },
  ],
  2018: [
    { month: '3月', content: 'Convertlab西安合资公司成立' },
    { month: '6月', content: '成为第一批京东云解决方案合作伙伴，拉开战略合作大幕' },
    { month: '8月', content: '在北京举办第二届中国MarTech（MTCC2018）峰会' },
    { month: '11月', content: '纳入浦发银行FinTech创新项目首批合作企业名录' },
    // { month: '12月', content: '与昆山杜克大学成立实验室启动AI marketing研究项目' },
  ],
  2017: [
    { month: '3月', content: '产品经受金融机构验证，可以支持千万+在线用户' },
    { month: '6月', content: '筹建服务运营子公司' },
    { month: '9月', content: 'DM Hub 2.0灰度发布在上海举办首届中国MarTech(MTCC2017)峰会' },
  ],
  2016: [
    { month: '6月', content: 'DM Hub产品正式发布' },
    { month: '8月', content: '成立销售团队，覆盖华北华东华南' },
  ],
  2015: [
    { month: '5月', content: 'Convertlab成立' },
    { month: '8月', content: '从1200多个团队中脱颖而出入选微软创投加速器第七期' },
    { month: '12月', content: '拥有第一个付费客户' },
  ],
};

function DevelopmentHistory() {
  const [currentYear, setCurrentYear] = useState('2021');
  const [mouseIn, setMouseIn] = useState(false);
  const [timer, setTimer] = useState();

  useEffect(() => {
    if (timer) clearInterval(timer);
    if (mouseIn) return () => {};
    const tempTimer = setInterval(() => {
      let index = HISTORY_LIST.indexOf(currentYear) + 1;
      if (index > HISTORY_LIST.length - 1) {
        index = 0;
      }
      setCurrentYear(HISTORY_LIST[index]);
    }, 5000);
    setTimer(tempTimer);
    return () => {
      if (tempTimer) clearInterval(tempTimer);
    };
  }, [currentYear]);

  // 悬停时不再自动切换；离开后继续
  const onMouseEnter = (year) => {
    clearInterval(timer);
    setCurrentYear(year);
    setMouseIn(true);
  };

  const onMouseLeave = () => {
    setMouseIn(false);
    const tempTimer = setInterval(() => {
      let index = HISTORY_LIST.indexOf(currentYear) + 1;
      if (index > HISTORY_LIST.length - 1) {
        index = 0;
      }
      setCurrentYear(HISTORY_LIST[index]);
    }, 1000);
    setTimer(tempTimer);
  };

  const HistoryItem = ({ year }) => {
    return (
      <button
        className={`year-item ${currentYear === year ? 'active' : ''}`}
        onMouseEnter={() => onMouseEnter(year)}
        onMouseLeave={onMouseLeave}
        type="button"
      >
        <span className="circle" />
        <span className="content">{year}</span>
      </button>
    );
  };

  HistoryItem.propTypes = {
    year: PropTypes.string,
  };
  return (
    <section id="development-history" className="development-history">
      <h3>发展历程</h3>
      <Container>
        <Row className="history-wrapper" gutter={{ lg: 0, md: 0, sm: 25, xs: 25 }}>
          <Col lg={{ span: 4, offset: 4 }} md={{ span: 4, offset: 4 }} sm={5} xs={5}>
            <div className="history-timeline-wrapper">
              <div className="line" />
              <div className="year-list">
                {HISTORY_LIST.map((year) => (
                  <HistoryItem key={year} year={year} />
                ))}
              </div>
            </div>
          </Col>
          <Col lg={12} md={12} sm={19} xs={19}>
            <div className="history-month-list">
              {HISTORY_MAP[currentYear].map((item) => (
                <Fade bottom duration={600} delay={200} distance="30px" key={`${currentYear}-${item.month}`}>
                  <div className="month-item">
                    <span className="month-title">{item.month}</span>
                    <span className="month-content">{item.content}</span>
                  </div>
                </Fade>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default DevelopmentHistory;
