import React from 'react';
import { Carousel } from 'antd';
import Layout from '../components/Layout';
import AboutUsBanner from '../components/AboutUsBanner';
import CorporateCulture from '../components/CorporateCulture';
import CoreValues from '../components/CoreValues';
import DevelopmentHistory from '../components/DevelopmentHistory';
import CompanyProfile from '../components/CompanyProfile';
import Contacts from '../components/Contacts';

export default () => {
  return (
    <Layout current="aboutus" subCurrent="about">
      {/*<Carousel>*/}
      <Carousel autoplay className="aboutus-carousel">
        <div>
          <AboutUsBanner
            title="关于我们"
            description="愿Convertlab成为全球企业值得信赖的营销伙伴"
            imgName="about"
          />
        </div>
        <div className="tree-wrapper">
          <a href="https://app.mokahr.com/campus_apply/convertlab/7422" target="_blank" rel="noreferrer noopener" />
        </div>
      </Carousel>
      {/* <AboutUsBanner */}
      {/*   title="关于我们" */}
      {/*   description="愿Convertlab成为全球企业值得信赖的营销伙伴" */}
      {/*   imgName="about" */}
      {/* /> */}
      <CompanyProfile />
      <CorporateCulture />
      <CoreValues />
      <DevelopmentHistory />
      <Contacts />
    </Layout>
  );
};
